import React, { useState, useEffect } from "react";
import {
  Button,
  Divider,
  Layout,
  Typography,
  Input,
  Tooltip,
  message,
  Popover,
} from "antd";
import MarkdownEditor from "./editor/MarkdownEditor";
import RecordingButton from "./editor/RecordingButton";
import { processAudioFromDemo } from "../libs/api";
import { Header } from "antd/es/layout/layout";
import { Selector } from "./demo/Selector";
import Sider from "antd/es/layout/Sider";
import colors from "../styles/colors";
import logo from "../logo.png";
import {
  QuestionCircleOutlined,
  LockOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
} from "@ant-design/icons";
import { UnlockPremiumModal } from "./demo/UnlockPremiumModal";
import { getAuth, signInAnonymously } from "firebase/auth";
import { useAuth } from "../hooks/useAuth";
import { useMediaQuery } from "react-responsive";
import TutorialStep from "./demo/TutorialStep";

const { Title } = Typography;

const CONTENT_FORMATS = [
  {
    text: "Email",
    emoji: "📧",
    description:
      "Standard email format with subject, greeting, body, and signature",
  },
  {
    text: "Document",
    emoji: "📄",
    description:
      "Structured content like reports, or articles with clear sections and formatting",
  },
  {
    text: "Notes",
    emoji: "📝",
    description: "Quick notes and bullet points",
  },
];
const STYLE_TYPES = [
  {
    text: "Friendly",
    emoji: "🙂",
    description:
      "Warm and personable, may include emojis and casual expressions",
  },
  {
    text: "Professional",
    emoji: "👔",
    description: "Business casual - friendly yet professional",
  },
  {
    text: "Formal",
    emoji: "💼",
    description: "Highly structured and traditional business writing",
  },
];
const WRITING_LANGUAGE = [
  {
    text: "User language",
    emoji: "🌐",
    description: "Write in the same language as the user",
  },
  {
    text: "English",
    emoji: "🇬🇧",
    description: "Write in English no matter the language from the user",
  },
];

type TutorialStep = {
  target: "sider" | "mic";
  title: string;
  content: string;
};

const TUTORIAL_STEPS: TutorialStep[] = [
  {
    target: "sider",
    title: "Choose your preferences",
    content:
      "First, let's customize your writing:\n" +
      "• Pick a format for your content\n" +
      "• Choose your writing style\n" +
      "• Select your preferred language\n" +
      "Don't worry, you can change these anytime!",
  },
  {
    target: "mic",
    title: "Start Speaking!",
    content:
      "Click the microphone and speak naturally. I'll transform your speech into polished text based on your preferences!\n\n" +
      "Try these examples:\n" +
      '• "Write a friendly email to thank my client John for purchasing our premium package last week. Mention that we\'re excited to start working with him."\n' +
      '• "Create professional meeting notes from yesterday\'s product roadmap discussion. Include the key decisions about Q3 features."\n\n' +
      "Need changes? Just click the mic again and say: " +
      '"Make it more formal" or "Rewrite the first paragraph to be more concise"\n\n' +
      "💡 Tip: You can speak in any language - I'll understand you!",
  },
];

function Demo() {
  const { user } = useAuth();
  const [localContent, setLocalContent] = useState("");
  const [selection, setSelection] = useState("");

  const [loading, setLoading] = useState(false);

  const [selectedContentFormat, setSelectedContentFormat] = useState(0);
  const [selectedStyleType, setSelectedStyleType] = useState(1);
  const [selectedWritingLanguage, setSelectedWritingLanguage] = useState(1);
  const [collapsed, setCollapsed] = useState(false);
  const [isSiderHovered, setIsSiderHovered] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  useEffect(() => {
    setLocalContent("");
    setSelection("");
    setLoading(false);
  }, []);

  useEffect(() => {
    setCollapsed(isMobile);
  }, [isMobile]);

  const [isPremiumModalVisible, setIsPremiumModalVisible] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    if (!auth.currentUser) {
      signInAnonymously(auth)
        .then(() => console.log("AL successful"))
        .catch((error) => console.error("AL failed:", error));
    } else {
      console.log("AL exists");
    }
  }, []);

  const handleRecording = async (blob: Blob) => {
    if (currentTutorialStep === 1) {
      handleFinishTutorial();
    }
    setLoading(true);
    try {
      let { content: updatedContent, transcript } = await processAudioFromDemo(
        blob,
        CONTENT_FORMATS[selectedContentFormat].text,
        STYLE_TYPES[selectedStyleType].text,
        WRITING_LANGUAGE[selectedWritingLanguage].text,
        selection || localContent
      );
      console.log("transcript", transcript);

      if (updatedContent) {
        if (selection) {
          updatedContent = localContent.replace(selection, updatedContent);
        }
        setLocalContent(updatedContent);
      }
    } catch (error) {
      message.error("Error processing your request");
    } finally {
      setLoading(false);
    }
  };

  console.log("localContent", localContent);

  const toggleSider = () => {
    setCollapsed(!collapsed);
  };

  const [currentTutorialStep, setCurrentTutorialStep] = useState(() => {
    return localStorage.getItem("hasCompletedTutorial") === "true" ? -1 : 0;
  });

  const handleNextStep = () => {
    setCurrentTutorialStep((prev) => prev + 1);
  };

  const handleFinishTutorial = () => {
    localStorage.setItem("hasCompletedTutorial", "true");
    setCurrentTutorialStep(-1);
  };

  const resetTutorial = () => {
    setCurrentTutorialStep(0);
  };

  return (
    <Layout style={styles.layout}>
      {currentTutorialStep >= 0 && <div style={styles.overlay} />}
      <Sider
        width={200}
        theme="light"
        collapsed={collapsed}
        collapsible
        trigger={null}
        style={{
          ...styles.sider,
          ...(currentTutorialStep === 0 && { zIndex: 999 }),
        }}
        onMouseEnter={() => setIsSiderHovered(true)}
        onMouseLeave={() => setIsSiderHovered(false)}
      >
        {currentTutorialStep === 0 && (
          <Popover
            open={currentTutorialStep === 0}
            content={
              <TutorialStep
                title={TUTORIAL_STEPS[0].title}
                content={TUTORIAL_STEPS[0].content}
                onNext={handleNextStep}
              />
            }
            placement="rightTop"
            align={{
              offset: [20, 20],
            }}
            overlayStyle={{ maxWidth: 350 }}
            overlayInnerStyle={{ padding: 16 }}
          >
            <div style={styles.siderContainer}>
              <div style={styles.brandContainer}>
                <img
                  src={logo}
                  alt="TalkText AI Logo"
                  style={styles.brandLogo}
                />
                {!collapsed && (
                  <Title level={4} style={styles.brandTitle}>
                    TalkText AI
                  </Title>
                )}
              </div>

              <Divider style={{ margin: "16px 0" }} />

              <Selector
                items={CONTENT_FORMATS}
                selectedIndex={selectedContentFormat}
                onSelect={setSelectedContentFormat}
                title={!collapsed ? "Format" : ""}
              />
              <Selector
                items={STYLE_TYPES}
                selectedIndex={selectedStyleType}
                onSelect={setSelectedStyleType}
                title={!collapsed ? "Style" : ""}
              />
              <Selector
                items={WRITING_LANGUAGE}
                selectedIndex={selectedWritingLanguage}
                onSelect={setSelectedWritingLanguage}
                title={!collapsed ? "Language" : ""}
              />

              {!collapsed && (
                <div style={styles.container}>
                  <Typography.Text strong style={styles.title}>
                    AI Instructions
                  </Typography.Text>
                  <Tooltip title="Unlock Premium to add custom AI instructions">
                    <div>
                      <Input.TextArea
                        disabled
                        placeholder='e.g., "always be concise, maintain a persuasive tone, use emojis"'
                        style={styles.customInstructions}
                        rows={10}
                      />
                    </div>
                  </Tooltip>
                </div>
              )}

              <Button
                type="text"
                icon={
                  collapsed ? <DoubleRightOutlined /> : <DoubleLeftOutlined />
                }
                onClick={toggleSider}
                style={{
                  ...styles.collapseButton,
                  opacity: isSiderHovered ? 1 : 0,
                }}
              />
            </div>
          </Popover>
        )}
        {currentTutorialStep !== 0 && (
          <div style={styles.siderContainer}>
            <div style={styles.brandContainer}>
              <img src={logo} alt="TalkText AI Logo" style={styles.brandLogo} />
              {!collapsed && (
                <Title level={4} style={styles.brandTitle}>
                  TalkText AI
                </Title>
              )}
            </div>

            <Divider style={{ margin: "16px 0" }} />

            <Selector
              items={CONTENT_FORMATS}
              selectedIndex={selectedContentFormat}
              onSelect={setSelectedContentFormat}
              title={!collapsed ? "Format" : ""}
            />
            <Selector
              items={STYLE_TYPES}
              selectedIndex={selectedStyleType}
              onSelect={setSelectedStyleType}
              title={!collapsed ? "Style" : ""}
            />
            <Selector
              items={WRITING_LANGUAGE}
              selectedIndex={selectedWritingLanguage}
              onSelect={setSelectedWritingLanguage}
              title={!collapsed ? "Language" : ""}
            />

            {!collapsed && (
              <div style={styles.container}>
                <Typography.Text strong style={styles.title}>
                  AI Instructions
                </Typography.Text>
                <Tooltip title="Unlock Premium to add custom AI instructions">
                  <div>
                    <Input.TextArea
                      disabled
                      placeholder='e.g., "always be concise, maintain a persuasive tone, use emojis"'
                      style={styles.customInstructions}
                      rows={10}
                    />
                  </div>
                </Tooltip>
              </div>
            )}

            <Button
              type="text"
              icon={
                collapsed ? <DoubleRightOutlined /> : <DoubleLeftOutlined />
              }
              onClick={toggleSider}
              style={{
                ...styles.collapseButton,
                opacity: isSiderHovered ? 1 : 0,
              }}
            />
          </div>
        )}
      </Sider>
      <Layout style={styles.contentLayout}>
        <Header style={styles.header}>
          <div style={styles.headerContent}>
            <Button
              type="default"
              onClick={resetTutorial}
              icon={<QuestionCircleOutlined />}
            >
              <Typography.Text style={styles.howToText}>
                How It Works
              </Typography.Text>
            </Button>

            <Button
              size="large"
              type="primary"
              onClick={() => setIsPremiumModalVisible(true)}
              icon={<LockOutlined />}
              style={{ display: "flex", alignItems: "center", gap: "8px" }}
            >
              <Typography.Text style={{ color: "white" }}>
                {isMobile ? "Premium" : "Unlock Premium Features"}
              </Typography.Text>
            </Button>
          </div>
        </Header>
        <Layout.Content style={styles.content}>
          <div style={styles.editorContainer}>
            <MarkdownEditor
              value={localContent}
              onChange={setLocalContent}
              onSelectionChange={setSelection}
            />
          </div>
          <div
            style={{
              ...styles.buttonContainer,
              zIndex: currentTutorialStep === 1 ? 999 : 0,
            }}
          >
            <Popover
              open={currentTutorialStep === 1}
              content={
                <TutorialStep
                  title={TUTORIAL_STEPS[1].title}
                  content={TUTORIAL_STEPS[1].content}
                  onNext={handleFinishTutorial}
                  onPrevious={() => setCurrentTutorialStep(0)}
                  isLastStep
                />
              }
              placement="top"
              overlayStyle={{ maxWidth: 350 }}
              overlayInnerStyle={{ padding: 16 }}
            >
              <RecordingButton
                handleRecording={handleRecording}
                loading={loading}
              />
            </Popover>
          </div>
        </Layout.Content>
      </Layout>
      <UnlockPremiumModal
        isOpen={isPremiumModalVisible}
        onClose={() => setIsPremiumModalVisible(false)}
      />
    </Layout>
  );
}

const styles: Record<string, React.CSSProperties> = {
  layout: {
    height: "100vh",
    background: "transparent",
  },
  header: {
    background: "transparent",
    padding: "0px",
  },
  headerContent: {
    display: "flex" as const,
    justifyContent: "space-between" as const,
    alignItems: "center" as const,
    padding: "0px 20px",
    height: "100%",
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  sider: {
    zIndex: 1,
    overflow: "visible",
    position: "sticky" as const,
    top: 0,
    left: 0,
    background: colors.background.paper,
  },
  siderContainer: {
    position: "relative" as const,
    height: "100%",
    marginTop: "20px",
    justifyContent: "center",
  },
  contentLayout: {
    display: "flex",
    height: "100%",
    background: "white",
  },
  content: {
    display: "flex",
    position: "relative",
    flexDirection: "column" as const,
    height: "calc(100% -  40px)",
    padding: "0px 20px",
  },
  editorContainer: {
    flexGrow: 1,
    overflow: "auto",
    marginBottom: "20px",
    border: "1px solid #d9d9d9",
    borderRadius: "4px",
  },
  buttonContainer: {
    position: "absolute",
    width: "100%",
    bottom: "40px",
    display: "flex",
    justifyContent: "center",
    pointerEvents: "none",
    zIndex: 999,
  },
  brandContainer: {
    display: "flex",
    padding: "0px 20px",
    alignItems: "center",
  },
  brandLogo: {
    width: "24px",
    height: "24px",
    marginRight: "12px",
  },
  brandTitle: {
    margin: 0,
    color: colors.primary.main,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    padding: "4px 20px",
    marginBottom: "1em",
  },
  title: {
    fontSize: "0.9em",
    textTransform: "uppercase",
    letterSpacing: "0.05em",
    color: "#666",
  },
  customInstructions: {
    maxWidth: "300px",
    borderRadius: "6px",
    resize: "none",
    backgroundColor: "#f5f5f5",
  },
  collapseButton: {
    position: "absolute",
    right: "-15px",
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 2,
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: colors.background.default,
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    opacity: 0,
    transition: "opacity 0.3s ease",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    zIndex: 998,
    pointerEvents: "auto",
  },
};

export default Demo;
