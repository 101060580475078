import React, { useState } from "react";
import { Button, Flex, Spin } from "antd";
import { AudioOutlined, CloseOutlined } from "@ant-design/icons";
import { useAudioRecorder } from "react-audio-voice-recorder";
import StopRecordingIcon from "../../icons/StopRecording";

interface RecordingButtonProps {
  disabled?: boolean;
  loading?: boolean;
  handleRecording: (blob: Blob) => void;
}

const RecordingButton: React.FC<RecordingButtonProps> = ({
  disabled,
  loading,
  handleRecording,
}) => {
  const [isRecording, setIsRecording] = useState(false);
  const [wasCancelled, setWasCancelled] = useState(false);
  const [recordedBlob, setRecordedBlob] = useState<Blob>();

  const { startRecording, stopRecording, recordingBlob } = useAudioRecorder();
  React.useEffect(() => setRecordedBlob(recordingBlob), [recordingBlob]);

  const toggleRecording = () => {
    if (isRecording) {
      stopRecording();
      setIsRecording(false);
    } else {
      startRecording();
      setIsRecording(true);
    }
  };

  React.useEffect(() => {
    if (recordedBlob && !wasCancelled) {
      handleRecording(recordedBlob);
    }
    setWasCancelled(false);
    setRecordedBlob(undefined);
  }, [recordedBlob, wasCancelled]);

  return (
    <Flex
      align="center"
      justify="center"
      gap={20}
      style={{ pointerEvents: "auto" }}
    >
      {isRecording && <div style={styles.cancelButton} />}
      <Button
        type="primary"
        icon={
          isRecording ? (
            <StopRecordingIcon style={styles.icon} />
          ) : loading ? (
            <Spin size="large" />
          ) : (
            <AudioOutlined style={styles.icon} />
          )
        }
        onClick={toggleRecording}
        size="large"
        disabled={loading || disabled}
        style={styles.floatingButton}
        shape="circle"
      />
      {isRecording && (
        <Button
          type="text"
          icon={<CloseOutlined style={styles.icon} />}
          onClick={() => {
            setWasCancelled(true);
            setIsRecording(false);
          }}
          style={styles.cancelButton}
          shape="circle"
        />
      )}
    </Flex>
  );
};

const styles = {
  floatingButton: {
    width: "80px",
    height: "80px",
  },
  cancelButton: {
    width: "50px",
    height: "50px",
  },
  icon: {
    fontSize: "24px",
  },
};

export default RecordingButton;
