import React from "react";
import { Button, Typography, Space } from "antd";
import { SmileOutlined, RightOutlined } from "@ant-design/icons";

const { Text, Title } = Typography;

interface TutorialStepProps {
  title: string;
  content: string;
  onNext: () => void;
  onPrevious?: () => void;
  isLastStep?: boolean;
}

const TutorialStep: React.FC<TutorialStepProps> = ({
  title,
  content,
  onNext,
  onPrevious,
  isLastStep = false,
}) => {
  return (
    <Space direction="vertical" size="small" style={styles.container}>
      <Space align="center">
        <SmileOutlined style={styles.icon} />
        <Title level={5} style={styles.title}>
          {title}
        </Title>
      </Space>
      <Text style={styles.content}>
        {content.split("\n").map((line, i) => (
          <React.Fragment key={i}>
            {line}
            {i < content.split("\n").length - 1 && <br />}
          </React.Fragment>
        ))}
      </Text>
      <div style={styles.buttonContainer}>
        {onPrevious ? <Button onClick={onPrevious}>Previous</Button> : <div />}
        <Button type="primary" onClick={onNext}>
          {isLastStep ? (
            "Got it!"
          ) : (
            <Space>
              Next
              <RightOutlined />
            </Space>
          )}
        </Button>
      </div>
    </Space>
  );
};

const styles = {
  container: {
    padding: "8px 4px",
    maxWidth: 300,
  },
  icon: {
    fontSize: "20px",
    color: "#1890ff",
  },
  title: {
    margin: 0,
  },
  content: {
    color: "#666",
    fontSize: "14px",
    lineHeight: "1.5",
  },
  button: {
    marginTop: 8,
    float: "right" as const,
  },
  buttonContainer: {
    marginTop: 16,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
};

export default TutorialStep;
