import React, { useCallback, useEffect, useMemo } from "react";
import {
  EditorEvents,
  useEditor,
  EditorContent,
  mergeAttributes,
} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { Color } from "@tiptap/extension-color";
import ListItem from "@tiptap/extension-list-item";
import TextStyle from "@tiptap/extension-text-style";
import MenuBar from "./MenuBar";
import TurndownService from "turndown";
import { marked } from "marked";
import { Flex } from "antd";
import { Node } from "@tiptap/core";
import Placeholder from "@tiptap/extension-placeholder";
import "./MarkdownEditor.css";

interface MarkdownEditorProps {
  value: string;
  onChange: (value: string) => void;
  onSelectionChange: (selection: string) => void;
}

const MarkdownEditor: React.FC<MarkdownEditorProps> = ({
  value,
  onChange,
  onSelectionChange,
}) => {
  const turndownService = useMemo(
    () => new TurndownService({ headingStyle: "atx" }),
    []
  );

  const extensions = useMemo(
    () => [
      Color.configure({ types: [TextStyle.name, ListItem.name] }),
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false,
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false,
        },
        paragraph: false,
      }),
      Node.create({
        name: "paragraph",
        priority: 1000,
        group: "block",
        content: "inline*",
        parseHTML() {
          return [{ tag: "div" }];
        },
        renderHTML({
          HTMLAttributes,
        }: {
          HTMLAttributes: Record<string, string>;
        }) {
          return [
            "div",
            mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
            0,
          ];
        },
      }),
      Placeholder.configure({
        placeholder:
          "Type here or click the 🎙️ microphone to start narrating your thoughts...",
        emptyEditorClass: "is-editor-empty",
        emptyNodeClass: "is-empty",
        showOnlyWhenEditable: true,
        includeChildren: false,
      }),
    ],
    []
  );

  const handleUpdate = useCallback(
    ({ editor }: EditorEvents["update"]) => {
      const markdown = turndownService.turndown(editor.getHTML());
      onChange(markdown);
    },
    [onChange, turndownService, extensions]
  );

  const handleSelectionUpdate = useCallback(
    ({ editor }: EditorEvents["selectionUpdate"]) => {
      const { from, to } = editor.state.selection;
      const selectedText =
        from === to ? "" : editor.state.doc.textBetween(from, to);
      onSelectionChange?.(selectedText);
    },
    [onSelectionChange]
  );

  const editor = useEditor({
    extensions,
    content: value,
    onUpdate: handleUpdate,
    onSelectionUpdate: handleSelectionUpdate,
    parseOptions: {
      preserveWhitespace: true,
    },
    editorProps: {
      attributes: {
        id: "editor",
        class: "sensitive ph-no-capture",
        style: "height: 100%; outline: none; border: none;",
      },
    },
    editable: true,
  });

  useEffect(() => {
    if (!editor) {
      return;
    }

    const markdown = turndownService.turndown(editor.getHTML());
    if (markdown !== value) {
      const html = marked(value);
      editor.commands.setContent(html);
    }
  }, [editor, value]);

  if (!editor) {
    return null;
  }

  return (
    <Flex vertical style={styles.container}>
      <MenuBar editor={editor} />
      <EditorContent
        editor={editor}
        style={styles.editorContent}
        className="ph-no-capture"
      />
    </Flex>
  );
};

export default MarkdownEditor;

const styles: Record<string, React.CSSProperties> = {
  container: {
    height: "100%",
    width: "100%",
    padding: "10px",
  },
  editorContent: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    alignItems: "stretch",
    justifyContent: "stretch",
    overflowY: "auto",
    marginTop: "10px",
  },
};
